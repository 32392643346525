<header id="header" [class.fixed]="fixedMenu || menuVisible">
  <nav class="large">
    <div class="max-width d-flex justify-between align-center">
      <div id="logo">
        <a [routerLink]="['/']" class="d-flex align-center">
          <img
            src="./assets/new_logo_{{ _uiType }}.png"
            alt="Sebastian Persson logotype"
            [class.fixed]="fixedMenu"
          />
        </a>
      </div>

      <div class="d-flex align-center sp-palettes">
        <div
          *ngFor="let palette of colorPalettes"
          [ngClass]="palette.class"
          (click)="changeUi(palette.name)"
          class="sp-palette pointer"
          [tabindex]="menuVisible || !isMobile ? 0 : -1"
          (keydown.enter)="changeUi(palette.name)"
          matTooltip="Change to {{ palette.name }} colorscheme"
        >
          <span class="visually-hidden">{{ palette.name }} design</span>
        </div>
      </div>

      <div
        class="sp-hamburger-menu-wrapper"
        (click)="toggleMenu()"
        [class.fixed]="fixedMenu || menuVisible"
        tabindex="0"
        (keydown.Enter)="toggleMenu()"
      >
        <div class="sp-hamburger-menu" [class.open]="menuVisible">
          <div class="sp-hamburger-menu-line"></div>
          <div class="sp-hamburger-menu-line"></div>
          <div class="sp-hamburger-menu-line"></div>
        </div>
      </div>

      <ul
        class="sp-main-menu-ul d-flex align-center"
        [ngClass]="{ block: menuVisible }"
      >
        <li>
          <a
            [tabindex]="menuVisible || !isMobile ? 0 : -1"
            class="pointer d-flex align-center"
            routerLink="about"
            routerLinkActive="active"
          >
            <mat-icon>person_outlined</mat-icon>
            <span> About </span>
          </a>
        </li>
        <!-- <li>
          <a
            [tabindex]="menuVisible || !isMobile ? 0 : -1"
            class="pointer d-flex align-center"
            routerLink="freebies"
            routerLinkActive="active"
          >
            <mat-icon>attribution</mat-icon>
            <span> Freebies </span>
          </a>
        </li> -->
        <li>
          <a
            [tabindex]="menuVisible || !isMobile ? 0 : -1"
            class="pointer d-flex align-center"
            routerLink="projects"
            routerLinkActive="active"
          >
            <mat-icon>work_outline</mat-icon>
            <span> Projects </span>
          </a>
        </li>
        <li>
          <a
            [tabindex]="menuVisible || !isMobile ? 0 : -1"
            class="pointer d-flex align-center"
            routerLink="contact"
            routerLinkActive="active"
          >
            <mat-icon>mail_outline</mat-icon>
            <span> Contact </span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>

<div
  class="sp-menu-open-overlay"
  (click)="closeMenu()"
  [class.animate-in]="menuVisible"
>
  <div class="sp-menu-open-overlay-close-wrapper">
    <mat-icon class="sp-menu-open-overlay-close" aria-label="Close menu"
      >close</mat-icon
    >
  </div>
</div>
