import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogData } from '../models/dialog-data';

@Component({
  selector: 'ae-dialog',
  templateUrl: './dialog.component.html',
})
export class DialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {}

  async submit(): Promise<void> {
    this.data.callback && (await this.data.callback());
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
