<div class="ae-dialog" [ngClass]="data.size" cdkTrapFocus>
  <div class="ae-dialog-header d-flex justify-between align-center">
    <h2 class="heading-2 ae-dialog-header-title">{{ data.title }}</h2>
    <mat-icon (click)="close()" aria-label="Close" class="pointer"
      >close</mat-icon
    >
  </div>
  <div class="ae-dialog-content">
    <p *ngIf="data.content" [innerHTML]="data.content"></p>
    <ng-content select="[dialog-content]"></ng-content>
  </div>
  <div class="ae-dialog-footer ae-button-group end d-flex justify-end">
    <button cdkFocusRegionEnd (click)="close()" class="btn">
      {{ data.secondaryLabel || "Cancel" }}
    </button>
    <button
      cdkFocusRegionEnd
      cdkInitialFocus
      (click)="submit()"
      class="inverted"
    >
      {{ data.primaryLabel || "Submit" }}
    </button>
  </div>
</div>
