import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { delay, mergeMap, take } from 'rxjs/operators';
import { isMobile } from 'src/app/core/functions/app.function';
import { Project } from 'src/app/shared/models/project';
import { ProjectService } from 'src/app/shared/services/project.service';
@Component({
  selector: 'sp-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit, AfterViewInit {
  @Input() parent = false;
  projects: Project[];
  isMobile = false;
  hasLoaded = false;
  fadeIn = false;
  showAll = false;

  constructor(private projectService: ProjectService) {}

  ngAfterViewInit(): void {
    // const grid = document.querySelector('.grid');
    // const msnry = new Masonry(grid, {
    //   itemSelector: '.grid-item',
    // });
    // console.log(msnry);
  }

  ngOnInit(): void {
    this.isMobile = isMobile();
    this.projectService
      .getAll()
      .pipe(
        take(1),
        delay(300),
        mergeMap((projects: Project[]) => {
          this.fadeIn = true;
          this.hasLoaded = true;
          return of(projects);
        }),
        delay(30)
      )
      .subscribe((projects: Project[]) => {
        this.projects = projects;
      });
  }
}
