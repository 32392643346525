<button
  class="ae-button d-flex align-center justify-center button-{{ buttonType }}"
  [class.disabled]="disabled || submitting"
  [class.full]="full"
  [tabindex]="disabled || submitting ? -1 : 0"
>
  <mat-icon
    *ngIf="buttonIconName && buttonIconPlacement === 'before'"
    class="ae-button-icon before"
    >{{ buttonIconName }}</mat-icon
  >

  <span class="ae-button-text">
    {{ text }}
  </span>

  <div
    *ngIf="submitting"
    class="ae-loader"
    [attr.aria-hidden]="submitting"
    aria-label="Reload icon"
    [class.blue]="buttonType === 'tertiary' || buttonType === 'inverted'"
  ></div>

  <mat-icon
    *ngIf="buttonIconName && buttonIconPlacement === 'after'"
    class="ae-button-icon after"
    >{{ buttonIconName }}</mat-icon
  >
</button>
