import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'sp-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Input() control: FormControl;
  @Output() imageChanged = new EventEmitter<string | ArrayBuffer>(null);
  @ViewChild('fileUpload') fileInput: ElementRef<HTMLInputElement>;

  constructor() {}

  ngOnInit(): void {}

  changeImage(): void {
    this.fileInput.nativeElement.click();
    this.fileInput.nativeElement.value = '';
  }

  thumbnailChanged($e: File[]): void {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      this.control.patchValue(e.target.result);
      this.imageChanged.emit(e.target.result);
    };

    reader.readAsDataURL($e[0]);
  }
}
