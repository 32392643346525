import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { isMobile } from '../../functions/app.function';

export enum UiTypes {
  Dark = 'dark',
  Light = 'light',
  Green = 'green',
  Brown = 'brown',
  Retro = 'retro',
}

@Component({
  selector: 'sp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  fixedMenu = false;
  menuVisible = false;
  _uiType: UiTypes;
  isMobile = false;

  colorPalettes = [
    {
      name: 'light',
      class: 'light-bg',
    },
    {
      name: 'dark',
      class: 'dark-bg',
    },
    {
      name: 'green',
      class: 'green-bg',
    },
    {
      name: 'brown',
      class: 'brown-bg',
    },
    {
      name: 'retro',
      class: 'retro-bg',
    },
  ];

  get uiType(): UiTypes {
    return localStorage.getItem('uiType') as UiTypes;
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.isMobile = isMobile();
  }

  @HostListener('window:scroll', ['$event'])
  scrollFunc($event: Event) {
    const scrollTop = $event['path'][1].scrollY;
    const limiter = isMobile() ? 100 : 200;
    this.fixedMenu = scrollTop > limiter;
    this.menuVisible = false;
  }

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeMenu();
      }
    });

    this.document.body.id = this.uiType || UiTypes.Light;
    this._uiType = this.uiType || UiTypes.Light;
  }

  scrollTo(id: string): void {
    const el: HTMLElement = document.getElementById(id);
    const menu: HTMLElement = document.getElementById('header');
    window.scrollTo({
      top: el.offsetTop - menu.offsetHeight,
      behavior: 'smooth',
    });
  }

  closeMenu(): void {
    this.menuVisible = false;
    this.document.body.classList.remove('sp-menu-open');
  }

  changeUi(uiType: UiTypes): void {
    this.document.body.id = uiType;
    this._uiType = uiType;
    localStorage.setItem('uiType', uiType);
  }

  toggleMenu(): void {
    this.menuVisible = !this.menuVisible;
    const action = this.menuVisible ? 'add' : 'remove';
    this.document.body.classList[action]('sp-menu-open');
  }
}
