import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Freebie } from '../models/freebie';

@Injectable({
  providedIn: 'root',
})
export class FreebieService {
  constructor(private http: HttpClient) {}

  getAll(): Observable<Freebie[]> {
    return this.http.get<Freebie[]>(`api/freebie`);
  }

  getOne(url: string): Observable<Freebie> {
    return this.http.get<Freebie>(`api/freebie/${url}`);
  }

  create(freebie: Freebie): Observable<Freebie> {
    return this.http.post<Freebie>(`api/freebie`, freebie);
  }

  update(freebie: Freebie): Observable<Freebie> {
    return this.http.put<Freebie>(`api/freebie/${freebie._id}`, freebie);
  }

  delete(id: string): Observable<Freebie> {
    return this.http.delete<Freebie>(`api/freebie/${id}`);
  }
}
