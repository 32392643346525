<section id="sp-projects" class="max-width">
  <header class="text-center">
    <h1 class="heading-1">More stuff</h1>
  </header>
  <div class="masonary-grid-wapper">
    <div class="d-flex align-center justify-center p-relative">
      <ngx-masonry class="grid" [ordered]="true">
        <div class="grid-item" ngxMasonryItem *ngFor="let freebie of freebies">
          <div class="p-relative d-flex grid-wrapper">
            <div class="sp-project-image">
              <figure>
                <img
                  [attr.src]="freebie.thumbnail"
                  [alt]="freebie.title"
                  [title]="freebie.title"
                  onerror="this.style.display='none'"
                />
              </figure>
            </div>

            <div class="grid-content">
              <div class="grid-inner-content">
                <h2 class="heading-2">
                  {{ freebie.title }}
                </h2>
                <div class="d-flex align-center sp-project-technologies wrap">
                  <div
                    class="sp-project-technology"
                    *ngFor="let technology of freebie.technologies"
                  >
                    {{ technology }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ngx-masonry>
    </div>
  </div>
</section>
