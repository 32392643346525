import { AngularEditorConfig } from '@kolkov/angular-editor';

export const EDITOR_CONFIG: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  minHeight: '0',
  maxHeight: 'auto',
  height: '400px',
  width: 'auto',
  minWidth: '0',
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Enter text here...',
  defaultParagraphSeparator: '',
  defaultFontName: '',
  defaultFontSize: '',
  fonts: [{ class: 'roboto', name: 'Roboto' }],
  customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText',
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ],
  // uploadUrl: 'v1/image',
  // upload: (file: File) => { ... }
  uploadWithCredentials: false,
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
};
