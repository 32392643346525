import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { take, delay, mergeMap } from 'rxjs/operators';
import { Freebie } from 'src/app/modules/adminpanel/models/freebie';
import { FreebieService } from 'src/app/modules/adminpanel/services/freebie.service';

@Component({
  selector: 'sp-freebies',
  templateUrl: './freebies.component.html',
  styleUrls: ['./freebies.component.scss'],
})
export class FreebiesComponent implements OnInit {
  fadeIn = false;
  hasLoaded = false;
  freebies: Freebie[];
  constructor(private freebieService: FreebieService) {}

  ngOnInit(): void {
    this.freebieService
      .getAll()
      .pipe(
        take(1),
        delay(300),
        mergeMap((freebies: Freebie[]) => {
          this.fadeIn = true;
          this.hasLoaded = true;
          return of(freebies);
        }),
        delay(30)
      )
      .subscribe((freebies: Freebie[]) => {
        this.freebies = freebies;
      });
  }
}
