import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe<T> implements PipeTransform {
  transform(arr: T[], prop: string): T[] {
    arr.sort((a: T, b: T): number => {
      return a[prop] > b[prop] ? 1 : -1;
    });
    return null;
  }
}
