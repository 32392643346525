import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

export enum ButtonType {
  Primary = 'primary',
  Inverted = 'inverted',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

@Component({
  selector: 'sp-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {
  @Input() text: string;
  @Input() buttonType: ButtonType = ButtonType.Primary;
  @Input() disabled = false;
  @Input() submitting = false;
  @Input() full = false;

  @Input() buttonIconName: string = '';
  @Input() buttonIconPlacement: 'before' | 'after' = 'before';
  @HostBinding('class.disabled') disbledClass =
    this.disabled || this.submitting;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const { disabled, submitting } = changes;
    if (disabled || submitting) {
      this.disbledClass = this.disabled || this.submitting;
    }
  }
}
