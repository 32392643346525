<footer class="sp-footer">
  <div class="max-width d-flex justify-between wrap">
    <section class="sp-footer-item">
      <p class="sp-footer-logo d-flex align-center wrap">
        <img src="./assets/footer-logo-white.png" alt="Logotype light" />
        <span class="hide-on-small">
          <span class="pre">Sebastian</span><br />
          Persson<span class="pre">.</span>
        </span>
      </p>

      <div class="sp-footer-item-socials d-flex w100">
        <a
          class="sp-footer-item-social-item"
          target="_blank"
          rel="nofollow, noindex"
          href="https://www.linkedin.com/in/sebastianpersson/"
        >
          <figure>
            <img
              class="social"
              src="./assets/socials/linkedin-round.svg"
              alt="Linkedin"
              title="LinkedIn"
            />
          </figure>
        </a>
        <a
          class="sp-footer-item-social-item"
          target="_blank"
          rel="nofollow, noindex"
          href="https://github.com/Archiie94"
        >
          <span class="sp-icon-github white" alt="Github" title="Github"></span>
        </a>
      </div>
    </section>

    <!-- Useful link section -->
    <section class="sp-footer-item">
      <h2 class="heading-2 light">Useful links</h2>
      <ul class="sp-footer-item-list">
        <li>
          <a [routerLink]="['/about']">About</a>
        </li>
        <!-- <li>
          <a [routerLink]="['/blog']">Blog</a>
        </li> -->
        <li>
          <a [routerLink]="['/projects']">Projects</a>
        </li>
        <li>
          <a [routerLink]="['/contact']">Contact</a>
        </li>
      </ul>
    </section>
    <!-- END: Useful link section -->

    <section class="sp-footer-item">
      <h2 class="heading-2 light">Direct contact</h2>

      <div class="sp-contact-details">
        <p class="content">
          <span class="contact">
            <i class="material-icons">contact_mail</i> sebbe-p@hotmail.com
          </span>
        </p>

        <p class="content">
          <span class="contact">
            <i class="material-icons">contact_phone</i> +4670-799 80 38
          </span>
        </p>
      </div>
    </section>
  </div>
  <div class="max-width">
    <aside class="sp-footer-copyright">
      <p class="max-width">
        Copyright &copy; 2021 - 2023.
        <a
          href="http://sebastianpersson.com"
          title="Web Designer, Web Developer, Angular"
          >SebastianPersson.com</a
        >
      </p>
    </aside>
  </div>
</footer>
