<div class="thumbnail">
  <sp-button
    text="Select thumbnail"
    [full]="true"
    buttonType="secondary"
    (click)="changeImage()"
  ></sp-button>

  <img [attr.src]="control.value" />

  <input
    #fileUpload
    type="file"
    accept="image/png, image/jpg"
    (change)="thumbnailChanged($event.target.files)"
  />
</div>
