import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

export function isMobile(): boolean {
  return document.body.offsetWidth <= 640;
}

export function markFormGroupTouched(formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach((control) => {
    control.markAsTouched();
    control.markAsDirty();

    if (control.controls) {
      this.markFormGroupTouched(control);
    }
  });
}

export function sort<T>(
  arr: T[],
  prop: string,
  order: 'desc' | 'asc' = 'desc'
) {
  arr.sort((a: T, b: T): number => {
    const multiplier = order === 'desc' ? 1 : -1;

    return a[prop] > b[prop] ? multiplier * 1 : multiplier * -1;
  });
}
