<section aria-label="About me" class="max-width sp-page">
  <header class="text-center">
    <h1 class="heading-1">About me</h1>
  </header>

  <div class="sp-about">
    <section class="sp-about-item clearfix">
      <h2 class="heading-2 sp-about-item-title">Development</h2>

      <div class="sp-box">
        <img
          class="sp-about-item-image hide-on-small"
          src="./assets/illustrations/developing.svg"
          width="300"
          alt="Image visualizing a developer"
        />
        <h3>How I got into development</h3>
        <p>
          A common question for developers might be "how did you get into
          development?", so therefore, I will explain my story. Prior to my
          developer career, I was more into design and user interactions in
          general. So my development background started when I was around 17
          years-old back in 2011. Back then I was studying in high school, and
          had a couple of programming courses in languages such as Java, PHP,
          and JavaScript. During that time I created my first "functional"
          website. The functional website that I developed back then was a
          car-rental website developed in PHP, MySQL, HTML, CSS and JavaScript.
          This was essentially the thing that made me go home and try to improve
          everything from design to interactions and database relations. The
          fact that I could create something interactive, usable, and functional
          with code was simply amazing and the driving factor.
        </p>

        <h3>Curiosity and new technlogies</h3>
        <p>
          With time, my curiosity took over and I started to work with different
          JavaScript frameworks and libraries such as jQuery, Angular.JS,
          React.JS and Node.JS. Back in 2013, I decided to study M.Sc. within
          Computer Science and because I wanted to avoid taking student loans, I
          had to work, and what better way than working with something you
          really love? So I started working as a freelancer and did a lot of
          freelance gigs in different programming languages. The Front-End
          languages I used was HTML5, CSS3 (Preprocessors such as SCSS)
          alongside with JavaScript libraries and frameworks such as Vanilla,
          jQuery, Angular.JS, Angular 2+, React.JS, Java (API's), PHP (Laravel
          and API's), Node.JS and of course MySQL, NoSQL (MongoDB). In short, I
          would take on any assignment as I knew the basics of APIs, database
          relations, key-value databases, Front-End development and had a lot of
          experience within UX, due to my interest in creating user-friendly and
          interactive applications. Throughout the time I studied (between
          2013-2018) I was apart of many different projects and at the same time
          spending a lot of time developing my own personal development, design
          and social skills.
        </p>
        <h3>Academic background</h3>
        <p>
          When I finished my B.Sc. in Computer Science (back in 2016), I still
          had two years remaining to get my M.Sc., and we were given the
          opportunity to select different tracks to be specialized within. The
          track that I selected was "M.Sc. in Interactive Media Technology",
          this is essentially a track within Computer Science, as it aims
          towards Human-Computer Interaction (HCI) and gave me a lot of insight
          into how we should think when we design interactive flows, how
          usability could be tested and measured, but also "how to design for
          the users/end-customers". After having my own freelance company for a
          couple of years and consulting for other companies I decided to try
          out some "on-site"-work. Ever since then (2016) have been working at a
          couple of different companies using a large variety of technology.
          Looking back at the end of my studies - I ended up with no-student
          loans and an M.Sc. degree within Interactive Media Technology
          Interested in which companies, and what I did there? See my
          <a
            href="https://cvfixer.se/profile/602b933a890d37.27895484"
            target="_blank"
            >online resume</a
          >.
        </p>
      </div>
    </section>

    <section class="sp-about-item clearfix">
      <h2 class="heading-2 sp-about-item-title">Family and personality</h2>

      <div class="sp-box">
        <img
          class="sp-about-item-image hide-on-small"
          src="./assets/illustrations/family.svg"
          width="300"
          alt="Image visualizing a developer"
        />

        <p>
          I'm a real family person and have always been one. I love my family,
          and to spend time with them. Recently I and my wife were gifted our
          second child. Now we have a daughter and a son.<br /><br />When it
          comes to work, it's very important for me to work with people who I
          feel comfortable with - not everyone needs to be friends outside work,
          however, it's very important for me to land in a team filled with
          positivity, good team spirit, and hard work mentality.<br /><br />I
          strongly believe in teamwork and that achieving goals together with
          the team is very motivating for me. Even though I'm a team player, I
          don't face any difficulties working alone on projects, since I have
          been doing it for so long during the time I was freelancing.
        </p>
      </div>
    </section>

    <section class="sp-about-item clearfix">
      <h2 class="heading-2 sp-about-item-title">Interests/Hobbies</h2>

      <div class="d-flex wrap justify-between">
        <div
          class="sp-about-item-sub-item sp-box clearfix"
          spAnimateIn
          animationDirection="bottomToTop"
        >
          <img
            class="sp-about-item-image"
            src="./assets/illustrations/developing.svg"
            width="100"
            height="100"
            alt="Image visualizing a guy working out as it's one of Sebastian's hobbies."
          />

          <div class="sp-about-me-item-content">
            <h3 class="heading-3">Development</h3>
            <p>
              One of my biggest hobbies is to spend time testing different
              technologies, stacks and work on hobby projects. Many of the
              projects that I have done aim towards simplifying my everyday
              life.
            </p>
          </div>
        </div>

        <div
          class="sp-about-item-sub-item sp-box clearfix"
          spAnimateIn
          animationDirection="bottomToTop"
        >
          <img
            class="sp-about-item-image"
            src="./assets/illustrations/workout.svg"
            width="100"
            height="100"
            alt="Image visualizing a guy working out as it's one of Sebastian's hobbies."
          />

          <div class="sp-about-me-item-content">
            <h3 class="heading-3">Workout</h3>
            <p>
              During my spare time I usually (except during the corona pandemic)
              workout (GYM) 5-6 times a week. This is one of the reasons to why
              I have had a lot of hobby projects directly related to weight-loss
              and workout.
            </p>
          </div>
        </div>

        <div
          class="sp-about-item-sub-item sp-box clearfix"
          spAnimateIn
          animationDirection="bottomToTop"
        >
          <img
            class="sp-about-item-image"
            src="./assets/illustrations/art.svg"
            width="100"
            height="100"
            alt="Image visualizing a guy working out as it's one of Sebastian's hobbies."
          />

          <div class="sp-about-me-item-content">
            <h3 class="heading-3">Abstract acrylic painting</h3>
            <p>
              Together with my wife we've got an interest in painting, more
              specifically, abstract acrylic art. In stressful periods I paint a
              lot since it decreases the stress and makes me relax.
            </p>
          </div>
        </div>

        <div
          class="sp-about-item-sub-item sp-box clearfix"
          spAnimateIn
          animationDirection="bottomToTop"
        >
          <img
            class="sp-about-item-image"
            src="./assets/illustrations/series.svg"
            width="100"
            height="100"
            alt="Image visualizing a guy working out as it's one of Sebastian's hobbies."
          />

          <div class="sp-about-me-item-content">
            <h3 class="heading-3">Watching TV-series</h3>
            <p>
              Another thing that I really love to do in my spare time is to
              watch TV series, with everything from Brooklyn Nine-Nine to Game
              of Thrones. I love all different genres, but prefer, Sci-fi or
              Comedy.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
