import { Component, Input } from '@angular/core';

@Component({
  selector: 'sp-year-progress',
  templateUrl: './year-progress.component.html',
  styleUrls: ['./year-progress.component.scss'],
})
export class YearProgressComponent {
  @Input() years: number;
  @Input() max: number = 10;
}
