import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimateInDirective } from './directives/animate-in.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { ProjectsComponent } from './components/projects/projects.component';
import { UiModule } from '../ui/ui.module';
import { RouterModule } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SortByPipe } from './pipes/sort-by.pipe';
import { TypeDirective } from './directives/type.directive';
import { NgxMasonryModule } from 'ngx-masonry';
import { FreebiesComponent } from './components/freebies/freebies.component';

@NgModule({
  declarations: [
    AnimateInDirective,
    ProjectsComponent,
    AboutComponent,
    SortByPipe,
    TypeDirective,
    FreebiesComponent,
  ],
  exports: [
    AnimateInDirective,
    ReactiveFormsModule,
    ProjectsComponent,
    MatTooltipModule,
    SortByPipe,
    TypeDirective,
    FreebiesComponent,
  ],

  imports: [
    UiModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgxMasonryModule,
  ],
})
export class SharedModule {}
