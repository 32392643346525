<section id="sp-projects" class="max-width" [class.sp-page]="!parent">
  <header class="text-center">
    <h1 class="heading-1" [class.no-margin-top]="parent">Featured Projects</h1>
  </header>

  <ng-container *ngIf="!hasLoaded">
    <article *ngFor="let i of [1, 2]" class="sp-project d-flex align-center">
      <div class="sp-project-image">
        <figure>
          <div class="skeleton-image">
            <img alt="" src="./assets/skeleton-loader/project-image.png" />
          </div>
        </figure>
      </div>

      <div class="sp-project-content">
        <header>
          <h2 class="sp-project-title skeleton-alignment heading-2 pointer">
            <div class="skeleton-text"></div>
          </h2>
        </header>
        <p class="sp-project-desc pointer skeleton-alignment">
          <span class="skeleton-text small"></span>
          <span class="skeleton-text small"></span>
          <span class="skeleton-text short small"></span>
        </p>
        <div class="d-flex align-center sp-project-technologies wrap">
          <span
            class="skeleton-text xsmall xshort sp-project-technology inline"
            *ngFor="let i of [1, 2, 3]"
          ></span>
        </div>

        <div
          class="sp-project-actions d-flex align-center justify-end"
          *ngIf="!isMobile"
        >
          <sp-button
            text="Read more"
            buttonType="secondary"
            buttonIconName="arrow_forward"
            buttonIconPlacement="after"
          ></sp-button>
        </div>
      </div>
    </article>
  </ng-container>

  <ng-container *ngIf="fadeIn">
    <article
      *ngFor="
        let project of projects | slice: 0:(showAll ? projects.length : 5)
      "
      class="sp-project d-flex align-center"
      spAnimateIn
      animationDirection="bottomToTop"
    >
      <a [routerLink]="['/project', project.url]" class="sp-project-image">
        <figure>
          <img
            [attr.src]="project.thumbnail"
            [alt]="project.title"
            [title]="project.title"
            onerror="this.style.display='none'"
          />
        </figure>
      </a>

      <div class="sp-project-content">
        <header>
          <h2
            class="sp-project-title heading-2 pointer"
            [routerLink]="['/project', project.url]"
          >
            {{ project.title }}
          </h2>
        </header>
        <p
          class="sp-project-desc pointer"
          [innerHTML]="project.ingress"
          [routerLink]="['/project', project.url]"
        ></p>
        <div class="d-flex align-center sp-project-technologies wrap">
          <div
            class="sp-project-technology"
            *ngFor="let technology of project.technologies"
          >
            {{ technology }}
          </div>
        </div>

        <div class="sp-project-actions d-flex align-center justify-end">
          <a
            class="sp-project-action hide-on-small"
            [routerLink]="['/project', project.url]"
          >
            <sp-button
              text="Read more"
              buttonType="secondary"
              buttonIconName="arrow_forward"
              buttonIconPlacement="after"
            ></sp-button>
          </a>
          <a
            *ngIf="project.link"
            [href]="project.link"
            class="sp-project-action"
            target="_blank"
            [attr.aria-label]="'External link to ' + project.title + ' project'"
            [matTooltip]="'External link to ' + project.title + ' project'"
          >
            <span
              [attr.aria-label]="
                'External link to ' + project.title + ' project'
              "
              class="sp-icon-external-link"
            >
              <span class="visually-hidden">
                External link to {{ project.title }} project
              </span>
            </span>
          </a>
          <a
            *ngIf="project.github"
            [href]="project.github"
            class="sp-project-action"
            target="_blank"
            [attr.aria-label]="
              'Link to github of ' + project.title + ' project'
            "
            [matTooltip]="'Link to github of ' + project.title + ' project'"
          >
            <span
              [attr.aria-label]="
                'Link to github of ' + project.title + ' project'
              "
              class="sp-icon-github"
            >
              <span class="visually-hidden">
                Link to github of {{ project.title }} project
              </span>
            </span>
          </a>
        </div>
      </div>
    </article>
    <div class="d-flex justify-center">
      <sp-button
        *ngIf="projects && !showAll"
        (click)="showAll = !showAll"
        buttonIconPlacement="after"
        buttonIconName="expand_more"
        [text]="showAll ? 'Show less projects' : 'Show all projects'"
      ></sp-button>
    </div>
  </ng-container>
</section>
