import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EDITOR_CONFIG } from './constants/editor-config';

@Component({
  selector: 'sp-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {
  @Input() control: FormControl;
  @Input() label: string;
  @Input() inputId = '';
  editorConfig = EDITOR_CONFIG;

  constructor() {}

  ngOnInit(): void {}
}
