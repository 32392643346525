import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[spType]',
})
export class TypeDirective implements AfterViewInit {
  @Input() hasLoaded = false;
  delay = 75;
  interval: ReturnType<typeof setInterval>;
  private _value = '';

  get value(): string {
    return this._value;
  }

  set value(val: string) {
    this._value = val;
  }

  get innerText(): string {
    return this.elRef.nativeElement.innerText;
  }

  set innerText(text: string) {
    this.elRef.nativeElement.innerText = text;
  }

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.elRef.nativeElement.style.opacity = 0;
    !this.hasLoaded && this._initType();
  }

  private _initType(): void {
    this.value = this.innerText;
    let tick = 1;
    this.interval = setInterval(() => {
      if (tick === 1) {
        this.elRef.nativeElement.style.opacity = 1;
      }
      const postfix = tick % 2 === 0 ? '|' : '';
      this.innerText = `${this.value.substr(0, tick)}${postfix}`;
      tick++;

      if (tick === this.value.length + 1) {
        clearInterval(this.interval);
        this._setLastCharTicks();
      }
    }, this.delay);
  }

  private _setLastCharTicks() {
    let tick = 0;
    this.interval = setInterval(() => {
      const postfix = tick % 2 === 0 ? '|' : '';
      this.innerText = `${this.value.substr(0, this.value.length)}${postfix}`;
      if (tick === 4) {
        clearInterval(this.interval);
        this.innerText = this.value.substr(0, this.value.length);
      }
      tick++;
    }, 400);
  }
}
