import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenResponse, UserDetails } from 'src/app/shared/models/user-details';
import { environment } from 'src/environments/environment';

export const AUTH_TOKEN_NAME = 'sp-auth-token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  apiUrl = environment.API_URL;
  private _token: string;

  get token(): string {
    return this._token;
  }

  constructor(private http: HttpClient, private router: Router) {
    this.getToken();
  }

  login(email: string, password: string): Observable<TokenResponse> {
    return this.http
      .post<TokenResponse>(`${this.apiUrl}/login`, { email, password })
      .pipe(
        map((data: TokenResponse) => {
          data.token && this.saveToken(data.token);
          return data;
        })
      );
  }

  isLoggedIn(): boolean {
    const user = this.getUserDetails();
    return user ? user.exp > Date.now() / 1000 : false;
  }

  getUserDetails(): UserDetails {
    const token = this.getToken();
    let payload;
    if (token) {
      payload = token.split('.')[1];
      payload = window.atob(payload);
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  register(
    name: string,
    email: string,
    password: string
  ): Observable<UserDetails> {
    return this.http.post<UserDetails>(`api/register`, {
      name,
      email,
      password,
    });
  }

  logout(): void {
    this._token = '';
    window.localStorage.removeItem(AUTH_TOKEN_NAME);
    this.router.navigateByUrl('/');
  }

  private saveToken(token: string): void {
    localStorage.setItem(AUTH_TOKEN_NAME, token);
    this._token = token;
  }

  private getToken(): string {
    if (!this.token) {
      this._token = localStorage.getItem(AUTH_TOKEN_NAME);
    }
    return this.token;
  }
}
