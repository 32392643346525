import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextareaComponent } from './textarea/textarea.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DialogModule } from './dialog/dialog.module';
import { ValidationErrorComponent } from './validation-error/validation-error.component';
import { ButtonComponent } from './button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { YearProgressComponent } from './year-progress/year-progress.component';
import { FileUploadComponent } from './file-upload/file-upload.component';

@NgModule({
  declarations: [
    InputComponent,
    TextareaComponent,
    ValidationErrorComponent,
    ButtonComponent,
    YearProgressComponent,
    FileUploadComponent,
  ],
  exports: [
    InputComponent,
    TextareaComponent,
    ButtonComponent,
    YearProgressComponent,
    FileUploadComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    DialogModule,
    MatIconModule,
  ],
})
export class UiModule {}
