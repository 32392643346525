import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { sort } from 'src/app/core/functions/app.function';
import { Project, ProjectPriority } from '../models/project';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private http: HttpClient) {}

  getAll(): Observable<Project[]> {
    return this.http.get<Project[]>('api/project').pipe(
      map((projects: Project[]) => {
        projects.forEach(
          (project: Project, index: number) =>
            (project.priority = project.priority > 0 ? project.priority : index)
        );
        sort(projects, 'priority', 'desc');
        return projects;
      })
    );
  }

  getOne(projectUrl: string): Observable<Project> {
    return this.http.get<Project>(`api/project/${projectUrl}`);
  }

  create(project: Project): Observable<Project> {
    return this.http.post<Project>(`api/project`, project);
  }

  update(project: Project): Observable<Project> {
    return this.http.put<Project>(`api/project/${project._id}`, project);
  }

  updateAllPrios(projects: ProjectPriority[]): Observable<Project> {
    return this.http.put<Project>(`api/project/priority/update`, projects);
  }

  delete(id: string): Observable<Project> {
    return this.http.delete<Project>(`api/project/${id}`);
  }
}
